import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import createSteps from "./createSteps";

const WISGPT_CONTEXT_TOKEN = loader("./wisGptContextToken.graphql");

export default compose(
  withRouter,
  withState("collapsed", "setCollapsed", false),
  graphql(WISGPT_CONTEXT_TOKEN, {
    name: "WISGPT_CONTEXT_TOKEN",
    options: ({ person, assessment }) => {
      return {
        variables: {
          scorecard_id: assessment.scorecard.id,
          user_id: person.id,
          type: "user",
        },
      };
    },
  }),
  withState(
    "steps",
    "setSteps",
    ({ person, evaluations, viewer, assessmentRows, notes }) =>
      createSteps({ person, evaluations, viewer, assessmentRows, notes })
  ),
  handleLoading(() => <FullPage />)
);
