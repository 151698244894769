import React, { useState, useEffect } from "react";

import Comment from "./Comment";
import EditorWrapper from "./EditorWrapper";
import serializer from "components/TextEditor/editorSerializer";
import styles from "./Comments.module.scss";

export default ({
  editorValue,
  setEditorValue,
  row,
  upsertNote,
  assessment,
  handleRemoveComment,
  editNote,
  setEditNote,
  notes,
  viewer,
  person,
}) => {
  const userComment = notes.find(
    (comment) => comment.created_by.id === viewer.id
  );
  const handleSave = () => {
    console.log("saveing");
    upsertNote({
      variables: {
        assessment_id: assessment.id,
        row_id: row.id,
        person_id: person.id,
        note: serializer.serialize(editorValue),
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <EditorWrapper
        defaultValue={editorValue}
        setEditorValue={setEditorValue}
        handleSave={
          !userComment || userComment.note !== serializer.serialize(editorValue)
            ? handleSave
            : null
        }
      />

      <div className={styles.notes}>
        {[...notes]
          .reverse()
          .filter((note) => note.row_id === row.id)
          .map((note) => {
            if (note.created_by.id === viewer.id) {
              return null;
            }
            return (
              <Comment
                key={"Comment-" + note.id}
                note={note}
                viewer={viewer}
                editNote={editNote}
                setEditNote={setEditNote}
                handleDeleteCandidateNote={handleRemoveComment}
              />
            );
          })}
      </div>
    </div>
  );
};
