import React, { useState } from "react";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import GPTGateway from "components/GPTGateway";
import ReactMarkdown from "react-markdown";
import { renderToStaticMarkup } from "react-dom/server";
import Popup from "reactjs-popup";
import styles from "./WisGPTInsights.module.scss";

export default ({
  scorecard_id,
  wisgptContextToken,
  prompt,
  assessmentRowId,
  examplePrompts,
  collapsed,
  setCollapsed,
  conversationId,
  setEditor,
}) => {
  return (
    <div className={styles.wisGpt}>
      {wisgptContextToken && (
        <GPTGateway
          conversationId={conversationId}
          requestType={"CANDIDATE_ASSESSMENT"}
          wisgptContextToken={wisgptContextToken}
          prompt={prompt}
          params={{ assessmentRowId }}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          messageActions={[
            {
              action: (content) => {
                const markdown = <ReactMarkdown>{content}</ReactMarkdown>;
                const htmlString = renderToStaticMarkup(markdown);

                setEditor(htmlString);
              },
              display: (
                <div className={styles.messageAction}>
                  <Button bStyle={["none"]}>
                    <Icon icon="SetAsNote"></Icon>
                  </Button>
                  <div className={styles.tooltip}>Copy-paste to note</div>
                </div>
              ),
            },
          ]}
          examplePrompts={
            examplePrompts
              ? examplePrompts
              : [
                  "Summarize this in one paragraph",
                  "What's their most relevant accomplishment in this area?",
                  "What's the biggest risk factor I should cover during my interview?",
                  "Summarise relevant facts from interview notes",
                ]
          }
        />
      )}
    </div>
  );
};
