import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import createSteps from "../createSteps";
import deserialize from "utils/deserialize";

const UPSERT_ASSESSMENT_NOTE = loader("./upsertAssessmentNote.graphql");
const UPSERT_ASSESSMENT_EVALUATION = loader(
  "./upsertAssessmentEvaluation.graphql"
);
const EVALUATIONS = loader("../../evaluations.graphql");
const NOTES = loader("../../notes.graphql");

export default compose(
  withRouter,
  withState("note", "setNote", ({ dim: { notes }, viewer }) => {
    const userNote = notes.find((note) => note.created_by.id === viewer.id);

    const note = userNote ? userNote.note : "";
    return note;
  }),
  withState("editNote", "setEditNote", false),
  graphql(UPSERT_ASSESSMENT_EVALUATION, {
    name: "saveEvaluation",
    options: ({
      steps,
      currentStep,
      setSteps,
      assessmentRows,
      viewer,
      match: {
        params: { id },
      },
    }) => ({
      update: (proxy, { data: { upsertAssessmentEvaluation } }) => {
        const data = proxy.readQuery({
          query: EVALUATIONS,
          variables: {
            assessment_id: parseInt(id, 10),
          },
        });

        const index = data.evaluations.findIndex(
          (e) => e.id === upsertAssessmentEvaluation.id
        );

        if (index === -1) {
          data.evaluations = [...data.evaluations, upsertAssessmentEvaluation];
        }

        proxy.writeQuery({
          query: EVALUATIONS,
          variables: {
            assessment_id: parseInt(id, 10),
          },
          data,
        });

        steps[currentStep].evaluation = upsertAssessmentEvaluation.evaluation;
        steps[currentStep].evaluations = data.evaluations;
        setSteps(steps);
      },
    }),
  }),

  graphql(UPSERT_ASSESSMENT_NOTE, {
    name: "upsertNote",
    options: ({
      steps,
      currentStep,
      setSteps,
      assessmentRows,
      viewer,
      match: {
        params: { id },
      },
    }) => ({
      update: (proxy, { data: { upsertAssessmentNote } }) => {
        const data = proxy.readQuery({
          query: NOTES,
          variables: {
            assessment_id: parseInt(id, 10),
          },
        });

        const index = data.assessmentNotes.findIndex(
          (e) => e.id === upsertAssessmentNote.id
        );

        if (index === -1) {
          data.assessmentNotes = [
            ...data.assessmentNotes,
            upsertAssessmentNote,
          ];
        }

        proxy.writeQuery({
          query: NOTES,
          variables: {
            assessment_id: parseInt(id, 10),
          },
          data,
        });

        steps[currentStep].notes = data.assessmentNotes.filter(
          (note) => note.row_id === steps[currentStep].id
        );
        setSteps(steps);
      },
    }),
  }),

  handleLoading(() => <FullPage />),
  withHandlers({
    handleSaveRating:
      ({ saveEvaluation, assessment }) =>
      async ({ row_id, evaluation }) => {
        await saveEvaluation({
          variables: {
            assessment_id: assessment.id,
            evaluation,
            row_id,
            person_id: assessment.person.id,
          },
        });
      },
  })
);
