import React, { useEffect } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import QueryString from "query-string";
import { Link } from "react-router-dom";
import NewTeam from "components/Modals/NewTeam";
import NewAccount from "components/Modals/NewAccount";
import InvitePeople from "components/Modals/InvitePeopleToOrg";
import { useSidebar } from "utils/sidebarProvider";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import ShowPositionTemplateToNonAdmin from "./ShowPositionTemplateToNonAdmin";
import LearningCenterCards from "./LearningCenter";
import Members from "./Members";
import UserCard from "./UserCard";
import Actions from "./Actions";
import BannerCTA from "./BannerCTA";
import compose from "./compose";

import styles from "../Dashboard.module.scss";

export default compose(
  ({
    TEAMS,
    LEARNING_CENTER_QUERY: { learningCenter },
    viewer,
    selectedOrg,
    history,
    modal,
    updateModal,
    handleCreatePosition,
    selectedTeam,
    setSelectedTeam,
    showPositionTemplate,
    account,
  }) => {
    useEffect(() => {
      if (showPositionTemplate) {
        history.replace(
          `${selectedOrg.id}/scorecards?scorecardName=${showPositionTemplate}`
        );
      }
    }, [showPositionTemplate]);

    useEffect(() => {
      const { create } = QueryString.parse(history.location.search);
      if (create === "newPosition" && selectedOrg.id) {
        history.replace(`${selectedOrg.id}/createPosition`);
      }
    }, [history.location.search, selectedOrg]);

    useEffect(() => {
      const { create } = QueryString.parse(history.location.search);
      if (create) {
        updateModal(create);
      }
    }, [history.location.search]);

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          {
            title: "People",
            action: () => history.push(`/org/${selectedOrg.id}/members`),
          },
          ,
          {
            title: "Teams",
            action: () => history.push(`/teams`),
          },
          {
            title: "Hiring",
            action: () => history.push(`/hiring`),
          },
          {
            title: "Scorecards",
            action: () => history.push(`/${selectedOrg.id}/scorecards`),
          },
          account.assessments && {
            title: "Assessments",
            action: () => history.push(`/workspace/${account.id}/assessments`),
          },
        ],
      },
      {
        items: [
          {
            title: "Learning Centre",
            action: () => {
              window.open("https://support.wisnio.com/en/", "_blank");
            },
          },
          {
            title: "Support",
            action: () => document.getElementById("supportButton").click(),
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [selectedOrg]);

    return (
      <React.Fragment>
        <div
          className={classNames(
            styles.row,
            selectedOrg.contract.role !== "candidate" && styles.start,
            viewer.surveys.filter((s) => s.complete).length <
              viewer.surveys.length && styles.incomplete
          )}
        >
          <div className={styles.usercard}>
            <UserCard viewer={viewer} selectedOrg={selectedOrg} />
            {viewer.surveys.filter((s) => s.complete).length <
              viewer.surveys.length && (
              <div className={styles.incomplete}>
                <div className={styles.left}>
                  <h5>
                    <span className={styles.icon}>
                      <Icon icon="Check" />
                    </span>
                    Complete your profile
                  </h5>
                  <div className={styles.progress}>
                    <p>
                      {viewer.surveys.filter((s) => s.complete).length +
                        " of " +
                        viewer.surveys.length}
                    </p>
                    <div className={styles.bar}>
                      <div
                        style={{
                          width:
                            (viewer.surveys.filter((s) => s.complete).length /
                              viewer.surveys.length) *
                              100 +
                            "%",
                        }}
                        className={styles.fill}
                      ></div>
                    </div>
                  </div>
                </div>
                <Link to="/surveys">Fill the next survey</Link>
              </div>
            )}
          </div>
          {selectedOrg.contract.role !== "candidate" ? (
            <div className={styles.actions}>
              <Actions
                selectedOrg={selectedOrg}
                history={history}
                updateModal={updateModal}
                handleCreatePosition={handleCreatePosition}
                data={TEAMS}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
              />
            </div>
          ) : (
            <div className={styles.actions}>
              <div className={styles.candidateNotice}>
                <p className={styles.subtitle}>
                  <span className={styles.icon}>
                    <Icon icon="Bulb" />
                  </span>
                  You have been added to this organisation as a candidate.
                </p>
                <p className={styles.info}>
                  We wish you the best of luck! In the meantime, check out your
                  <br />
                  <Link to="/personal">Personal Profile</Link> for insights into
                  your values, personality, and competencies.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.section}>
          <div className={styles.top}>
            <div className={styles.left}>
              <h3 className={styles.title}>Learning Centre</h3>
              <p className={styles.subtitle}>
                Learn how to get the most out of Wisnio
              </p>
            </div>
          </div>
          <LearningCenterCards cards={learningCenter} />
        </div>
        {/* {selectedOrg.contract.role !== "admin" &&
          selectedOrg.contract.role !== "owner" && (
            <div className={styles.banner}>
              <BannerCTA action={() => updateModal("newAccount")} />
            </div>
          )} */}
        {selectedOrg.adminRights && (
          <div className={styles.section}>
            <div className={styles.top}>
              <div className={styles.left}>
                <h3 className={styles.title}>Members</h3>
                <p className={styles.subtitle}>Overview of your organisation</p>
              </div>
              <div className={styles.right}>
                <Button
                  bStyle={["secondary", "verySmall"]}
                  onClick={() => updateModal("invitePeople")}
                >
                  <Icon icon="Plus" marginRight />
                  Invite people
                </Button>
              </div>
            </div>
            <Members selectedOrg={selectedOrg} />
          </div>
        )}
        {modal === "newTeam" && (
          <Modal
            isOpen={modal === "newTeam"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "create")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal("")}
            >
              <i className="fal fa-times" />
            </Button>
            <NewTeam organization={selectedOrg} updateModal={updateModal} />
          </Modal>
        )}
        {modal === "newAccount" && (
          <Modal
            isOpen={modal === "newAccount"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "create")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal("")}
            >
              <i className="fal fa-times" />
            </Button>
            <NewAccount updateModal={updateModal} />
          </Modal>
        )}
        {modal === "invitePeople" && (
          <Modal
            isOpen={modal === "invitePeople"}
            onRequestClose={() => updateModal("")}
            className={styles.modal}
          >
            <InvitePeople
              organization={selectedOrg}
              updateModal={updateModal}
              setOverlayClose={false}
            />
          </Modal>
        )}
        {selectedOrg &&
          selectedOrg.id &&
          !selectedOrg.adminRights &&
          showPositionTemplate && (
            <ShowPositionTemplateToNonAdmin
              template={showPositionTemplate}
              orgId={selectedOrg.id}
            />
          )}
      </React.Fragment>
    );
  }
);
